import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"
import Layout from "../layouts/layout"
import SEO from "../layouts/seo"
import Footer from "../global/footer"
import Nav from '../global/nav';
import PageBody from '../body/page-body'
import queryString from 'query-string';
import Fuse from 'fuse.js';
import animateScrollTo from 'animated-scroll-to';
import AnimatedLink from "../global/animatedLink"
import { treeParentRecursive, generateSlug } from './../../helpers/urlBuilder';
import { searchPageFragment, searchBasePageFragment, searchArticlePageFragment, searchFaqPageFragment } from './../fragments/search-page-fragment';
import stripHtml from 'striptags';
// @ts-ignore
import ChevronRightBlue from '../../svg/chevron-right-blue-search.svg'
// @ts-ignore
import ArrowLeft from '../../svg/arrow-page-blue-left.svg'
// @ts-ignore
import ArrowRight from '../../svg/arrow-page-blue-right.svg'
import AccordionModule from './../modules/accordion-module';
import { buildClient } from '@datocms/cma-client-browser';
import ReactPaginate from 'react-paginate';
import { useSiteSearch } from 'react-datocms';
interface props {
  transitionStatus: string
  data?: any
  location?: any
}

let rawPages = [];
let rawArticles = [];
let rawFaq = [];
let rawFaqSearchTags = [];
let searchStartup = false;
let hasArticles = false;
let totalPages = 0;

let initedCategory = false;

export default function TemplateBasePage({transitionStatus, data, location}:props) {
  let term:any = null;
  const client = buildClient({ apiToken: "39bf0101661117758af15fda599433" });

  const {pageData, basepageData, articleData, faqData, hubData} = data;
  const [searchTerm, setSearchTerm] = useState(null);
  const [articles, setArticles] = useState([]);
  const [pages, setPages] = useState([]);
  const [faq, setFaq] = useState([]);
  const [faqTag, setFaqTag] = useState([]);
  const [searchCategory, setSearchCategory] = useState('Page');
  const searchState = location.state ? location.state.value : null;
  const maxPageVisible = 5;
  const itemsPerPage = pageData.resultsPerPage;
  const [itemsToShow, setItemsToShow] = useState([0, 8]);
  const [paginationPage, setPaginationPage] = useState(1);
  const [isBlank, setIsBlank] = useState(false);
  let justLoaded = false;
  const [userAgent, setUA] = useState<any>();
  const [emptyResult, setEmptyResult] = useState<boolean>(false);
  const pageSize = 12;
 
  let currentFaqResults;
  let currentArticleResults;
  
  const datoResults:{ state:any, error?:any, data?:any } = useSiteSearch({
    client,
    initialState: { locale: 'en' },
    buildTriggerId: process.env.GATSBY_BUILD_TRIGGER_ID!,
    fuzzySearch:true, 
    highlightMatch: (text, key, context) =>
    context === 'title' ? (
      <mark key={key}><strong key={key}>{text}</strong></mark>
    ) : (
      <mark key={key}><strong key={key}>{text}</strong></mark>
    ),
    resultsPerPage: pageSize,
  });
  useEffect(()=>{    
    setEmptyResult(datoResults.data && !datoResults.data.totalResults);  
    
    if(!initedCategory){
      initedCategory = true;
      
      
      if( datoResults.data?.length ){
        setCategory({cat: 'Page', length: datoResults.data?.length});
      }else 
      if(currentArticleResults?.length || currentFaqResults?.length){
        setCategory(    
          //pageResults.length > 0 ? {cat: 'Page', length: pageResults.length} :
          currentFaqResults?.length > 0 ? {cat: 'FAQ', length: currentFaqResults?.length}   :
          currentArticleResults?.length > 0 ? {cat: 'Article', length: currentArticleResults?.length}
          : {cat: null, length: 0}
        )
      }else{
        setCategory({cat: 'Page', length: datoResults.data?.length});
      }
      
    }
    
    
  });
 

  //console.log("constructed", searchState)


  //console.log(datoResults)
  useEffect(() => {
    if(typeof window !== 'undefined'){      
      setUA(navigator.userAgent)
    }
  },[]);


  useEffect(() => {
    let { query } = queryString.parse(location.search);

    
    if(!searchState && document.location.search.indexOf("?q=") > -1 && !query){
      query = decodeURI(document.location.search.split("=")[1]);
      query = query.replace(new RegExp("__pct__", "igm"), "%");
      query = query.replace(new RegExp("__and__", "igm"), "&");
    }else if(!query && searchState){
      query = searchState;
    }
    
    if(term !== query){
      term = query;
      datoResults.state.setQuery(term);      
    }
    
    if (searchState !== null) {
      setSearchTerm(searchState);
    } else if (query) {
      setSearchTerm(query);
    }
    if (!searchStartup) {
      cleanupData()
      searchStartup = true;
    }
    if (!justLoaded) {
      document.querySelector('nav').classList.add('no-hover');
      setTimeout(() => document.querySelector('nav').classList.remove('no-hover'), 1000);
      justLoaded = true;
    }
    
    initSearch(searchTerm)
  }, [searchState, searchTerm]);

  
  const cleanupData = (): void => {
    // Pages
    for (let i = 0; i < basepageData.edges.length; i++) {
      const rawPage = basepageData.edges[i].node;
      if (rawPage.slug !== 'home' && !rawPage.campaignPage) {
        rawPages.push(rawPage);
      }
    }
    
    // Article Hubs (uses pages)
    for (let i = 0; i < hubData.edges.length; i++) {
      const rawPage = hubData.edges[i].node;
      rawPages.push(rawPage);
    }

    // Articles
    for (let i = 0; i < articleData.edges.length; i++) {
      const rawArticle = articleData.edges[i].node;
      rawArticle.parsedTitle = stripHtml(rawArticle.title);
      rawArticles.push(rawArticle);
    }

    // Accordions
    for (let i = 0; i < faqData.components.length; i++) {
      const faqComponent = faqData.components[i];
      if (faqComponent.__typename == 'DatoCmsAccordionModule') {
        rawFaq.push(faqComponent);
      }
    }
    
    // Accordion searchtags
    for (let i = 0; i < faqData.components.length; i++) {
      const faqComponent = faqData.components[i];
      if (faqComponent.__typename == 'DatoCmsAccordionModule') {
        for (let z = 0; z < faqComponent.accordionItems.length; z++) {
          const accordion = faqComponent.accordionItems[z];
          const searchItem = {
            title: accordion.title,
            tags: accordion.searchTags.map(tag => tag.searchTag)
          }
          rawFaqSearchTags.push(searchItem);
        }
      }
    }
  }
  
  const initSearch = (term) => {
    

    if (term == null) {
      setIsBlank(true);
      return
    }
    setIsBlank(false);
    const baseOptions = {
      includeScore: true,
      includeMatches: true,
      threshold: 0.2,
      location: 0,
      distance: 500,
      maxPatternLength: 32,
    }

    const pageOptions = {
      ...baseOptions,
      keys: [
        {name: 'pageTitle', weight: 1},
        {name: 'searchTags.searchTag', weight: 0.5}
      ]
    }

    const articleOptions = {
      ...baseOptions,
      keys: [
        {name: 'parsedTitle', weight: 1},
        {name: 'searchTags.searchTag', weight: 0.5}
      ]
    }

    const faqOptions = {
      ...baseOptions,
      keys: [
        {name: 'accordionItems.title', weight: 1},
        {name: 'accordionItems.searchTags.searchTag', weight: 0.5}
      ]
    }

    const faqSearchTagOptions = {
      ...baseOptions,
      keys: [
        {name: 'title', weight: 1},
        {name: 'tags', weight: 0.5}
      ]
    }

    //const pageFuse = new Fuse(rawPages, pageOptions);
    const articleFuse = new Fuse(rawArticles, articleOptions);
    const faqFuse = new Fuse(rawFaq, faqOptions);
    const faqTagFuse = new Fuse(rawFaqSearchTags, faqSearchTagOptions);

    const articleResults = currentArticleResults = articleFuse.search(term);
    //const pageResults = pageFuse.search(term);
    const faqResults = currentFaqResults = faqFuse.search(term);
    const faqTagResults = faqTagFuse.search(term);

    setArticles(articleResults)
    //setPages(pageResults)
    setFaq(faqResults)
    setFaqTag(faqTagResults.map(tag => tag.item.title))

    //hasArticles = pageResults.length > 0 ||  articleResults.length > 0 || faqResults.length > 0;
    hasArticles = articleResults.length > 0 || faqResults.length > 0;

    /*
    if( datoResults.data?.length ){
      setCategory({cat: 'Page', length: datoResults.data?.length});
    }else 
    if(articleResults.length || faqResults.length){
      setCategory(    
        //pageResults.length > 0 ? {cat: 'Page', length: pageResults.length} :
        faqResults.length > 0 ? {cat: 'FAQ', length: faqResults.length}   :
        articleResults.length > 0 ? {cat: 'Article', length: articleResults.length}
        : {cat: null, length: 0}
      )
    }else{
      setCategory({cat: 'Page', length: datoResults.data?.length});
    }*/
    
    
  }

  const setCategory = ({cat, length}) => {
    animateScrollTo(0, {speed: 4000})
    totalPages = Math.ceil(length / itemsPerPage);  
    handlePageChange(1)
    setSearchCategory(cat)
  }
  const datoPageChange = (pageNumber) => {
    datoResults.state.setPage(pageNumber);
    setTimeout(()=>animateScrollTo(0, {speed: 1000}), 100);
  }
  const handlePageChange = (pageNumber) => {
    if (pageNumber < 1) return null;
    if (pageNumber > totalPages) return null;
    // const latestArticlesEl = document.querySelector(".search-body");
    let firstIndex = pageNumber == 1 ? 0 : pageNumber * itemsPerPage - itemsPerPage;
    let lastIndex = pageNumber == 1 ? itemsPerPage : pageNumber * itemsPerPage;
    setPaginationPage(pageNumber);
    setItemsToShow([firstIndex, lastIndex])
    setTimeout(()=>animateScrollTo(0, {speed: 4000}), 100);
    // @ts-ignore
    // setTimeout(() => animateScrollTo(latestArticlesEl.offsetTop - 150, {speed: 2000}), 100);
  }
  const calcPageButtonsArticles = () => {
    let pageButtonElements = [];
    let buffer = (maxPageVisible - 1) / 2; 

    for (let i = 0; i < totalPages; i++) {
      let thisPage = i + 1;
      // Show all pages if in the visible limit
      if (totalPages <= maxPageVisible) {
        
        pageButtonElements.push(
          <button 
            className={`search-body__pagination-item number ${paginationPage == thisPage ? 'active' : ''}`} 
            key={thisPage}
            onClick={() => handlePageChange(thisPage)}
          >
            {thisPage}
          </button>
        )
      // Show all pages if in the visible limit
      } else if (
        // If pagination page
        thisPage == paginationPage ||
        // Pages either side of buffer number
        (thisPage >= (paginationPage - buffer) && thisPage < paginationPage) ||
        (thisPage <= (paginationPage + buffer) && thisPage > paginationPage) ||
        // On either end under max pages visible
        thisPage <= maxPageVisible && paginationPage <= buffer + 1 ||
        thisPage > (totalPages - maxPageVisible) && paginationPage >= (totalPages - buffer)
      ) {
        
        pageButtonElements.push(
          <button 
            className={`search-body__pagination-item number ${paginationPage == thisPage ? 'active' : ''}`} 
            key={thisPage}
            onClick={() => handlePageChange(thisPage)}
          >
            {thisPage}
          </button>
        )
      }
    }
    return pageButtonElements
  }
  const calcPageButtons = () => {
    let pageButtonElements = [];
    let buffer = (maxPageVisible - 1) / 2; 

    for (let i = 0; i < datoResults?.data.totalPages; i++) {
      let thisPage = i;
      // Show all pages if in the visible limit
      if (totalPages <= maxPageVisible) {
        
        pageButtonElements.push(
          <button 
            className={`search-body__pagination-item number ${datoResults.state.page == thisPage ? 'active' : ''}`} 
            key={thisPage}
            onClick={() => datoPageChange(thisPage)}
          >
            {thisPage+1}
          </button>
        )
      // Show all pages if in the visible limit
      } else if (
        // If pagination page
        thisPage == paginationPage ||
        // Pages either side of buffer number
        (thisPage >= (paginationPage - buffer) && thisPage < paginationPage) ||
        (thisPage <= (paginationPage + buffer) && thisPage > paginationPage) ||
        // On either end under max pages visible
        thisPage <= maxPageVisible && paginationPage <= buffer + 1 ||
        thisPage > (totalPages - maxPageVisible) && paginationPage >= (totalPages - buffer)
      ) {
        
        pageButtonElements.push(
          <button 
            className={`search-body__pagination-item number ${datoResults.state.page == thisPage ? 'active' : ''}`} 
            key={thisPage}
            onClick={() => datoPageChange(thisPage)}
          >
            {thisPage+1}
          </button>
        )
      }
    }
    return pageButtonElements
  }

  return (
    <>
      <SEO
        title={pageData.pageTitle} 
        metaDescription={pageData.metaDescription} 
        ogDescription={pageData.ogDescription} 
        ogTitle={pageData.ogTitle} 
        ogImage={pageData.ogImage} 
      />
      <Nav isWebView={userAgent ? false : true}/>
      
      <Layout
        className={`${pageData.slug}`}
        transitionStatus={transitionStatus}
        isWebView={userAgent ? false : true}
      >
        <div className="search">
          <div className="outer-container">
            <div className="inner-container">
              <div className="search-header">
                {hasArticles ? 
                  <p className="search-header__title">Page Type</p>
                : null}
                <div className="search-header__buttons">
                {datoResults.data?.pageResults.length > 0 && !isBlank ?
                    <button
                      className={`search-header__button ${searchCategory == 'Page' ? 'active' : ''}`}
                      onClick={() => setCategory({cat: 'Page', length: datoResults.data?.length})}
                    >
                      Pages
                      {/* Pages <span className={`count`}>{pages.length}</span> */}
                    </button>
                  : !emptyResult ? <button
                  className={`search-header__button ${searchCategory == 'Page' ? 'active' : ''}`}
                  onClick={() => setCategory({cat: 'Page', length: datoResults.data?.length})}
                  style={{display:!isBlank ? 'inline-block' : 'none'}}
                > 
                  Searching pages...
                  
                </button> : null}
                  {faq.length > 0 && !isBlank ?
                    <button
                      className={`search-header__button ${searchCategory == 'FAQ' ? 'active' : ''}`}
                      onClick={() => setCategory({cat: 'FAQ', length: faq.length})}
                    >
                      FAQs
                      {/* FAQs <span className={`count`}>{faq.length}</span> */}
                    </button>
                  : null}
                  
                  {articles.length > 0 && !isBlank ?
                    <button
                      className={`search-header__button ${searchCategory == 'Article' ? 'active' : ''}`}
                      onClick={() => setCategory({cat: 'Article', length: articles.length})}
                    >
                      Articles
                      {/* Articles <span className={`count`}>{articles.length}</span> */}
                    </button>
                  : null}
                  
                  
                  
                </div>
                
                
                {searchCategory  == 'FAQ' ?
                  <p className="search-header__subtitle">
                    Showing {faqTag.length}
                      <span className={`category`}> {searchCategory} </span> 
                    result{faqTag.length > 1 ? 's ' : ' '}  for 
                      <span className={`term`}> {searchTerm}</span> 
                  </p>
                : searchCategory  == 'Page' ?
                  datoResults.data?.pageResults.length ?
                    <p className="search-header__subtitle">
                      Showing {((datoResults.state?.page)*pageSize)+1} to {((datoResults.state.page)*pageSize)+datoResults.data?.pageResults?.length}
                        <span className={`category`}> {searchCategory} </span>
                        result{datoResults.data?.totalPages > 1 ? 's ' : ' '} 
                        of {datoResults.data?.totalResults} for <span className={`term`}>{searchTerm}</span> 
                    </p>
                  :  (emptyResult && searchTerm) ?  
                    <p className="search-header__subtitle">Unfortunately, there are <strong>0</strong> results for <strong>"{searchTerm}"</strong>, please try searching again.</p>               
                    : (isBlank ? <p className="search-header__subtitle">To start searching, type what you’re looking for by clicking on the search icon above.</p> : <p className="search-header__subtitle">Loading search results...</p>)
                   
                : searchCategory  == 'Article' ?
                  totalPages > 1 ?
                    <p className="search-header__subtitle">
                      Showing {itemsToShow[0] + 1} to {itemsToShow[1] < articles.length ? itemsToShow[1] : articles.length}
                        <span className={`category`}> {searchCategory} </span>
                        result{pages.length > 1 ? 's ' : ' '} 
                        of {articles.length} for <span className={`term`}>{searchTerm}</span> 
                    </p>
                  : 
                    <p className="search-header__subtitle">
                      Showing {articles.length} 
                        <span className={`category`}> {searchCategory} </span>
                        result{articles.length > 1 ? 's ' : ' '} 
                        for <span className={`term`}>{searchTerm}</span> 
                    </p>
                :
                  <p className="search-header__subtitle">
                    Unfortunately there are 0 results for <span className={`term`}>{searchTerm}, </span> 
                     please try searching again.
                  </p>
                }
              </div>

              <div className={`search-body ${searchCategory}`}>
                {searchCategory == 'Page' ?
                  <div className="search-body__list">
                    {datoResults.data?.pageResults.map((result, index) => (
                      <div>
                          <AnimatedLink 
                            to={result.url.indexOf("?") > -1 ? `${result.url}&search-term=${searchTerm}` : `${result.url}?search-term=${searchTerm}`}
                            excerpt={result.bodyExcerpt}
                            className={`search-body__list-item`}
                            key={`result-${index}`}
                            >
                            {result.title}
                            <ChevronRightBlue/>
                          </AnimatedLink>
                          
                      </div>
                      
                      
                    ))}
                    {pages.slice(...itemsToShow).map((page, index) => {
                      let parents = [];
                      treeParentRecursive(page.item, parents);
                      let slug = generateSlug(page.item.slug, parents);
                      return (
                        <AnimatedLink 
                          to={slug}
                          className={`search-body__list-item`}
                          key={`result-${index}`}
                        >
                          {page.item.pageTitle}
                          <ChevronRightBlue/>
                        </AnimatedLink>
                      )
                    })}
                  </div>
                : searchCategory == 'FAQ' ?
                  <div className="search-body__list">
                    {faq.map((faqItem, index) => {
                      const allAccordions = faqItem.item.accordionItems;
                      const filteredAccordions = allAccordions.filter(acc => faqTag.includes(acc.title));
                      const filteredAccordionModule = {
                        ...faqItem.item,
                        accordionItems: filteredAccordions
                      };

                      return (
                        <AccordionModule 
                          key={`faq-${index}`} 
                          data={filteredAccordionModule} 
                          isSearch={true}
                          openFirst={index == 0 && faq.length == 1 ? true : false}
                        />
                      )
                    })}
                  </div>
                : searchCategory == 'Article' ?
                  <div className="search-body__list">
                    {articles.slice(...itemsToShow).map((article, index) => {
                      let parents = [];
                      treeParentRecursive(article.item, parents);
                      let slug = generateSlug(article.item.slug, parents);
                      return (
                        <AnimatedLink 
                          to={slug}
                          className={`search-body__list-item`}
                          key={`result-${index}`}
                        >
                          {stripHtml(article.item.title)}
                          <ChevronRightBlue/>
                        </AnimatedLink>
                      )
                    })}
                  </div>
                : null}
              </div>
            </div>
            
            {datoResults.data?.totalPages > 1 && searchCategory === 'Page' ?
              <div className="search-body__pagination-wrapper">
                <div className="search-body__pagination">
                
                  <button 
                    className={`search-body__pagination-item search-body__pagination-first disabled`}
                    onClick={() => datoPageChange(0)}
                  >
                    First
                  </button>
                  <button 
                    className={`search-body__pagination-item search-body__pagination-prev disabled`}
                    onClick={() => datoPageChange((datoResults.state.page-1) > 0 ? datoResults.state.page-1 : 0)}
                  >
                    <ArrowLeft/>
                  </button>
                  {calcPageButtons()}
                  <button 
                    className={`search-body__pagination-item search-body__pagination-next disabled`}
                    onClick={() => datoPageChange((datoResults.state.page+1) < datoResults.data?.totalPages-1 ? datoResults.state.page+1 : 0)}
                  >
                    <ArrowRight/>
                  </button>
                  <button 
                    onClick={() => datoPageChange(datoResults?.data.totalPages-1)}
                    className={`search-body__pagination-item search-body__pagination-last disabled`}
                  >
                    Last
                  </button>
                </div>
                {searchCategory  == 'Page' ?
                  <p className="search-body__pagination-pages">Page {datoResults.state.page+1} of {datoResults.data.totalPages}</p>
                : searchCategory  == 'Article' ?
                  <p className="search-body__pagination-pages">Page {datoResults.state.page+1} of {datoResults.data.totalPages}</p>
                : null}
              </div>
            : null}
            {totalPages > 1 && searchCategory !== 'FAQ' && hasArticles ?
              <div className="search-body__pagination-wrapper">
                <div className="search-body__pagination">
                  <button 
                    className={`search-body__pagination-item search-body__pagination-first disabled`}
                    onClick={() => handlePageChange(1)}
                  >
                    First
                  </button>
                  <button 
                    className={`search-body__pagination-item search-body__pagination-prev disabled`}
                    onClick={() => handlePageChange(paginationPage - 1)}
                  >
                    <ArrowLeft/>
                  </button>
                  {calcPageButtonsArticles()}
                  <button 
                    className={`search-body__pagination-item search-body__pagination-next disabled`}
                    onClick={() => handlePageChange(paginationPage + 1)}
                  >
                    <ArrowRight/>
                  </button>
                  <button 
                    onClick={() => handlePageChange(totalPages)}
                    className={`search-body__pagination-item search-body__pagination-last disabled`}
                  >
                    Last
                  </button>
                </div>
                {searchCategory  == 'Page' ?
                  <p className="search-body__pagination-pages">Page {paginationPage} of {Math.ceil(pages.length / itemsPerPage)}</p>
                : searchCategory  == 'Article' ?
                  <p className="search-body__pagination-pages">Page {paginationPage} of {Math.ceil(articles.length / itemsPerPage)}</p>
                : null}
              </div>
            : null}
          </div>
          
          
        </div>
        <div className="page-body">
          <PageBody components={pageData.components}/>
        </div>
        <Footer isWebView={userAgent ? false : true}/>
      </Layout>
    </>
  )
}

export const query = graphql`
  query {
    pageData: datoCmsSearch {
      ...searchPageFragment,
      __typename,
      components{
        __typename
      }
      
    }
    basepageData: allDatoCmsBasePage {
      edges {
        node {
          ...searchBasePageFragment,
          __typename,
          components{
            __typename
          }
        }
      }
    }
    articleData: allDatoCmsArticlePage {
      edges {
        node {
          ...searchArticlePageFragment,
          __typename,
          components{
            __typename
          }         
        }
      }
    }
    hubData: allDatoCmsArticleHub {
      edges {
        node {
          ...searchHubPageFragment,
          __typename
         
        }
      }
    }
    faqData: datoCmsBasePage(slug: {eq: "faq"}) {
      ...searchFaqPageFragment,
      __typename,
      components{
        __typename
      }
     
    }
  }
`